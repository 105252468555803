.App {
  text-align: center;
}

.spotifyAuthButton{
    background:white;
    border: 0;
    cursor: pointer;
    padding: 2em;
}
